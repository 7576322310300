#calculation {
    .box {
        h2 {
            font-size: 14px;
            color: $text-secondary-color;
            font-weight: 500;
            margin-bottom: 5px;
        }

        p {
            font-size: 14px;
            line-height: 22px;
            margin-bottom: 20px;
        }
    }
}