#customization-edit {
    .cost-grouping {
        border: solid 1px $box-border-color;
        margin-top: $elements-margin;

        button {
            height: 100%;
            width: 35px;
            background-color: transparent;
            border: none;

            &:disabled {
                opacity: .3;
            }
        }

        .header {
            padding: 12px;
            border-bottom: solid 1px $box-border-color;
            display: flex;
            align-items: center;
            justify-content: space-between;
            
            span {
                color: $text-secondary-color;
                font-weight: 500;
                font-size: 14px;
            }
        }

        ul {
            list-style: none;

            li {
                padding: 12px;
                display: flex;
                align-items: center;

                &.dark {
                    background-color: #F0F0F0;
                }

                .cost-desc {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-right: 15px;

                    > span:nth-child(1) {
                        color: $text-secondary-color;
                        font-size: 14px;
                    }

                    > span:nth-child(2) {
                        color: $text-tertiary-color;
                        font-size: 14px;
                    }
                }
                
            }
        }
    }
}