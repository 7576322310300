.input-colors-group {
    border: solid $input-border-width $input-label-color;
    border-radius: $border-radius-default;
    height: $input-boxes-height;
    overflow-y: auto;

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    > ul {
        > li {
            display: flex;
            flex-direction: column;

            > button {
                font-size: 14px;
                position: relative;
                display: flex;
                align-items: center;
                color: $text-secondary-color;
                background-color: $bg-page;
                padding: 10px;
                border: none;
                // border-top: solid $input-border-width $input-border-color;
                // border-bottom: solid $input-border-width $input-border-color;

                span:nth-child(2) {
                    padding-top: 2px;
                    font-size: 12px;
                    margin-left: 20px;
                    color: $text-tertiary-color;
                }

                > img {
                    position: absolute;
                    right: 15px;
                    top: 15px;
                }
            }
            
            > ul {
                display: none;
                > li > button {
                    font-size: 14px;
                    width: 100%;
                    display: flex;
                    border: none;
                    background-color: transparent;
                    padding: 10px;
                    align-items: center;
                    color: $text-secondary-color;
                    font-weight: 500;

                    .color-desc {
                        display: flex;
                        align-items: center;
                        width: 60%;
                    }

                    .color-sku {
                        color: $text-tertiary-color;
                    }
                }
            }

            &.active {
                button > img {
                    transform: rotate(-90deg);
                }
                > ul {
                    display: block;
                }
            } 
        }
    }
}