.input-group {
    position: relative;
    width: 100%;
    margin-bottom: $input-margin-bottom;
    margin-top: 5px;

    .input-color-content {
        display: flex;
        align-items: center;
        width: 100%;
        height: 45px;
        border-radius: 3px;
        border: solid $input-border-width $input-label-color;
        padding: 0 15px;

        > span:last-child {
            width: 100%;
            padding: 0;
            border: none;
            border-radius: $border-radius-default;
            overflow: hidden;
        }
    }

    label {
        font-size: 13px;
        font-weight: $input-label-weight;
        color: $input-label-color;
        position: absolute;
        left: 10px;
        background-color: #fff;
        padding: 0 10px;
        top: -8px;
    }
}